import type { TagGoal } from "@trainwell/types";
import { useSurvey } from "src/components/Survey";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { track } from "src/lib/btracking";
import { selectGoalTags, setTagGoal } from "src/slices/surveySlice";
import Tags from "./components/Tags";
import TagsPageLayout from "./components/TagsPageLayout";
import { goalCopy } from "./data/challengeData";

export default function TagGoals() {
  const dispatch = useAppDispatch();
  const { nextPage } = useSurvey();
  const tags = useAppSelector(selectGoalTags);

  function selectTag(tag: string) {
    dispatch(setTagGoal(tag as TagGoal));
  }

  const onClick = () => {
    track("Challenge Select", { tags: tags.length > 0 ? tags : ["empty"] });
    nextPage();
  };

  return (
    <>
      <TagsPageLayout
        title="What is your primary fitness goal?"
        subtitle="Select whichever is most important to you. Your trainer can work with you to accomplish multiple goals."
        onContinue={onClick}
      >
        <Tags tags={goalCopy} onTagClick={selectTag} selected={tags} />
      </TagsPageLayout>
    </>
  );
}
