import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import type { CoachMatch } from "@trainwell/api-sdk";
import { LoadingComponent } from "@trainwell/ui";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useSurvey } from "src/components/Survey";
import ErrorDialog from "src/components/misc/ErrorDialog";
import PhoneNumberInput from "src/components/misc/PhoneNumberInput";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { track } from "src/lib/btracking";
import { event } from "src/lib/fpixel";
import { frontChatIdentify } from "src/lib/frontChat";
import { logGTagConversion, setGTagUserParams } from "src/lib/gtag";
import { selectActiveTests, trackEvent } from "src/slices/analyticsSlice";
import { setupClient } from "src/slices/clientSlice";
import {
  selectPotentialCoachStatus,
  selectTrainer,
} from "src/slices/coachSlice";
import {
  setEmail,
  setFirstName,
  setLastName,
  setPhoneNumber,
} from "src/slices/surveySlice";
import * as yup from "yup";
import CoachCard from "./CoachCard";
import CoachCard_June from "./CoachCard_June";

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const validationSchema = yup.object({
  firstName: yup.string().trim().required("Required"),
  lastName: yup.string().trim().required("Required"),
  email: yup
    .string()
    .trim()
    .matches(emailRegex, "Must be a valid email")
    .required("Required"),
  phone: yup.string().trim().min(5).required("Required"),
});

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
};

const surveyButtonTitle = (forcedTrainerId?: string) => {
  if (!forcedTrainerId) return "See my trainer";
  return "Continue";
};

export default function SelectCoach() {
  const dispatch = useAppDispatch();
  const survey = useSurvey();
  const potentialCoaches = useAppSelector(
    (state) => state.coach.recommendedCoaches,
  );
  const justification = useAppSelector(
    (state) => state.coach.coachJustification,
  );
  const justificationBullets = useAppSelector(
    (state) => state.coach.coachJustificationBullets,
  );
  const activeTestsIds = useAppSelector(selectActiveTests);

  const forcedTrainerId = useAppSelector(
    (state) => state.coach.forcedTrainerId,
  );

  const submitSurveyStatus = useAppSelector(
    (state) => state.client.submitSurveyStatus,
  );
  const email = useAppSelector((state) => state.survey.email);

  const firstName = useAppSelector((state) => state.survey.firstName);
  const lastName = useAppSelector((state) => state.survey.lastName);
  const clientFullName = useAppSelector(
    (state) => state.survey.firstName + " " + state.survey.lastName,
  );
  const userId = useAppSelector((state) => state.client.clientID);
  const pontentialCoachStatus = useAppSelector(selectPotentialCoachStatus);

  const now = new Date();
  const countStartDate = new Date(2023, 10, 28);
  const minsPassed = Math.round(
    (now.getTime() - countStartDate.getTime()) / 1000 / 60,
  );
  const numClientsMatched = 113000 + minsPassed;

  function trackCoachShown(coach: CoachMatch) {
    track("Coach Shown", {
      fullName: coach.full_name,
      trainerId: coach.trainer_id,
    });
    dispatch(
      trackEvent({
        event_type: "coach_shown",
        event_content: {
          trainer_id: coach.trainer_id,
          full_name: coach.full_name,
        },
      }),
    );
  }

  function getCoachCards() {
    if (activeTestsIds.includes("jun_multiple_trainers_single")) {
      return (
        <CoachCard_June
          trainer={potentialCoaches[0]}
          selectTrainerHandler={onSelectTrainer}
        />
      );
    } else if (
      activeTestsIds.includes("jun_multiple_trainers_multiple") ||
      activeTestsIds.includes("jun_multiple_trainers_availability")
    ) {
      return potentialCoaches.map((coach) => (
        <CoachCard_June
          key={coach.trainer_id}
          trainer={coach}
          selectTrainerHandler={onSelectTrainer}
        />
      ));
    } else {
      return (
        <CoachCard
          justificationBullets={justificationBullets}
          trainer={potentialCoaches[0]}
          selectTrainerHandler={onSelectTrainer}
        />
      );
    }
  }

  useEffect(() => {
    if (submitSurveyStatus !== "succeeded") {
      return;
    }
    if (pontentialCoachStatus === "succeeded") {
      if (
        activeTestsIds.includes("jun_multiple_trainers_multiple") ||
        activeTestsIds.includes("jun_multiple_trainers_availability")
      ) {
        //If we are in a test with multiple trainers, log a coach_shown for each one
        potentialCoaches.forEach((coach) => trackCoachShown(coach));
      } else {
        //Otherwise just log a coach_shown for the 0th coach
        trackCoachShown(potentialCoaches[0]);
      }
    }

    setGTagUserParams({ email, firstName, lastName });
    logGTagConversion({ conversionKey: "SIGN_UP" });
    frontChatIdentify({ fullName: clientFullName, userId });
  }, [
    submitSurveyStatus,
    pontentialCoachStatus,
    clientFullName,
    email,
    firstName,
    lastName,
    userId,
    potentialCoaches,
    dispatch,
  ]);

  const handleSetupClientFulfilled = () => {
    const forcedTrainer = potentialCoaches[0];
    if (!forcedTrainerId || !forcedTrainer) return;
    // We have a forced coach ID, immediately select the trainer for the user.
    onSelectTrainer(forcedTrainer);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(setFirstName(values.firstName));
      dispatch(setLastName(values.lastName!));
      dispatch(setEmail(values.email));
      dispatch(setPhoneNumber(values.phone));
      dispatch(setupClient()).then(({ meta }) => {
        if (meta.requestStatus === "rejected") return;
        handleSetupClientFulfilled();
      });

      event("Lead");
    },
  });

  function onSelectTrainer(trainer: CoachMatch) {
    const trainerID = trainer.trainer_id;
    const topTrainer = potentialCoaches.slice(0, 3);

    dispatch(
      trackEvent({
        event_type: "select_coach",
        event_content: {
          trainer_id_top: potentialCoaches[0].trainer_id,
          trainer_id_selected: trainerID,
          trainer_ids_suggested: topTrainer.map(
            (trainer) => trainer.trainer_id,
          ),
        },
      }),
    );
    track("Coach Select", {
      fullName: trainer.full_name,
      trainerId: trainer.trainer_id,
    });
    dispatch(selectTrainer(trainer));
    survey.nextPage();
  }

  return (
    <Container maxWidth="lg">
      {submitSurveyStatus !== "succeeded" && <></>}
      {submitSurveyStatus === "succeeded" &&
        forcedTrainerId === undefined &&
        (pontentialCoachStatus === "succeeded" ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "16px",
            }}
          >
            {(activeTestsIds.includes("jun_multiple_trainers_multiple") ||
              activeTestsIds.includes(
                "jun_multiple_trainers_availability",
              )) && (
              <Typography
                //@ts-expect-error
                variant="YTBig"
                sx={{
                  fontSize: 28,
                  marginBottom: "16px",
                  fontWeight: "bold",
                  lineHeight: 1,
                  color: "#443564",
                  textAlign: "center",
                }}
              >
                Meet your trainer matches!
              </Typography>
            )}
            {activeTestsIds.includes("jun_multiple_trainers_single") && (
              <Typography
                //@ts-expect-error
                variant="YTBig"
                sx={{
                  fontSize: 28,
                  marginBottom: "16px",
                  fontWeight: "bold",
                  lineHeight: 1,
                  color: "#443564",
                }}
              >
                Meet your trainer!
              </Typography>
            )}
            {getCoachCards()}
          </Box>
        ) : pontentialCoachStatus === "failed" ? (
          <ErrorDialog
            title="Something went wrong on our end!"
            body="Please contact our support team for help at support@trainwell.net or through the chat at the bottom right of the screen."
            buttonCTA="Reload app"
            onClick={() => {
              document.cookie =
                "; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
              localStorage.clear();
              sessionStorage.clear();
              location.pathname = "/";
            }}
          />
        ) : (
          <LoadingComponent message="Loading trainer profile..." />
        ))}
      {submitSurveyStatus === "succeeded" && forcedTrainerId !== undefined && (
        <Stack justifyContent="center" alignItems="center" width="100%">
          <Stack alignItems="center" width="300px" gap="24px">
            You&apos;ve already setup your profile
            <Button type="submit" onClick={() => survey.nextPage()} fullWidth>
              Continue
            </Button>
          </Stack>
        </Stack>
      )}
      <Dialog
        open={submitSurveyStatus !== "succeeded"}
        PaperProps={{
          sx: {
            m: { xs: 2, sm: 4 },
          },
        }}
      >
        <DialogTitle sx={{ mt: 1, fontWeight: "bold" }}>
          {forcedTrainerId === undefined
            ? `Complete your profile to view your trainer match!`
            : `Complete your profile!`}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2} sx={{ mb: 4, mt: 0.25 }}>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  label="First name"
                  fullWidth
                  color="primary"
                  autoComplete="given-name"
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  label="Last name"
                  fullWidth
                  color="primary"
                  autoComplete="family-name"
                  name="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  color="primary"
                  label="Email"
                  autoComplete="email"
                  type="email"
                  name="email"
                  value={formik.values.email}
                  onChange={(e) => {
                    formik.setFieldValue(
                      e.target.name,
                      e.target.value.replace(/\s+/g, ""),
                    );
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    (formik.touched.email && Boolean(formik.errors.email)) ||
                    submitSurveyStatus === "succeeded-info-exists"
                  }
                  helperText={
                    (formik.touched.email && formik.errors.email) ||
                    (submitSurveyStatus === "succeeded-info-exists"
                      ? "The email and/or phone number provided are already in use"
                      : undefined)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <PhoneNumberInput
                  name="phone"
                  showLabel={true}
                  placeholder="234343"
                  defaultCountry="us"
                  preferredCountries={["us", "gb"]}
                  disableAreaCodes={true}
                  onBlur={formik.handleBlur}
                  error={
                    (formik.touched.phone && Boolean(formik.errors.phone)) ||
                    submitSurveyStatus === "succeeded-info-exists"
                  }
                  onChange={(e: any) => {
                    formik.setFieldValue("phone", e.replace(/\D/g, ""));
                  }}
                  helperText={
                    (formik.touched.phone && formik.errors.phone) ||
                    (submitSurveyStatus === "succeeded-info-exists"
                      ? "The email and/or phone number provided are already in use"
                      : undefined)
                  }
                />
              </Grid>
            </Grid>
            <Button type="submit" fullWidth disabled={!formik.isValid}>
              {submitSurveyStatus !== "succeeded" ? (
                surveyButtonTitle(forcedTrainerId)
              ) : (
                <CircularProgress
                  size={32}
                  sx={{ color: "white" }}
                ></CircularProgress>
              )}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
      {submitSurveyStatus === "failed" && (
        <ErrorDialog
          title="Something went wrong submitting your information!"
          body="Please contact our support team for help at support@trainwell.net or through the chat at the bottom right of the screen."
          buttonCTA="Reload app"
          onClick={() => {
            document.cookie =
              "; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            localStorage.clear();
            sessionStorage.clear();
            location.pathname = "/";
          }}
        ></ErrorDialog>
      )}
    </Container>
  );
}
