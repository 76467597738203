import { Box, Dialog, DialogContent, DialogTitle, Fade } from "@mui/material";
import { getIsInfluencerPaired } from "@trainwell/features";
import { useEffect, useState, type ReactNode } from "react";
import { useLocation } from "react-router";
import { useAppSelector } from "src/hooks/stateHooks";
import { useUrlParams } from "src/hooks/useUrlParams";
import BrandFooter from "./BrandFooter";
import NavBar from "./NavBar";
import Progress from "./Progress";

type LayoutPageProps = {
  children: ReactNode;
};

export default function Layout({ children }: LayoutPageProps) {
  const location = useLocation();
  const pagePaths = useAppSelector((state) => state.navigation.pagePaths);
  const activePagePaths = pagePaths.filter((page) => page.active === true);
  const pageIndex = activePagePaths.findIndex(
    (path) => path.path === location.pathname,
  );
  const pairInfleuncerUserId = useAppSelector(
    (state) => state.client.pairInfluencerUserId,
  );
  const [didMouseLeave, setDidMouseLeave] = useState(false);
  const [isInfluencerPaired, setIsInfluencerParied] = useState<boolean | null>(
    null,
  );

  const { blue_nav_bar } = useUrlParams("blue_nav_bar");

  // This logic checks is the pairInfleuncerUserId which was poassed as a query param has already been paired with an influencer
  // These are SINGLE-USE links given to influencers, allowing us to track their signups and do fun data stuff
  // If the link has already been used, we show an error dialog
  useEffect(() => {
    if (pairInfleuncerUserId && isInfluencerPaired === null) {
      getIsInfluencerPaired(pairInfleuncerUserId).then((response) => {
        setIsInfluencerParied(response.is_paired);
      });
    }
  }, [pairInfleuncerUserId, isInfluencerPaired]);

  if (isInfluencerPaired === true) {
    return (
      <Dialog open={true} onClose={() => {}}>
        <DialogTitle>Error, link already used</DialogTitle>
        <DialogContent>
          Reach out to support@trainwell.net if you think this is an error.
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <div onMouseLeave={() => setDidMouseLeave(true)}>
      <NavBar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: "1 0 auto",
        }}
      >
        <Box
          sx={{
            mt: 0,
            pt: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {activePagePaths[pageIndex]?.hideNavigation !== true && (
            <Fade appear={true} in={true}>
              <Box
                sx={{
                  mb: 4,
                  display: "flex",
                }}
              >
                <Progress value={pageIndex} />
              </Box>
            </Fade>
          )}
          {children}
        </Box>

        <BrandFooter />
      </Box>
    </div>
  );
}
