import AccessibilityNewRounded from "@mui/icons-material/AccessibilityNewRounded";
import AddReactionRounded from "@mui/icons-material/AddReactionRounded";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import Battery0BarRounded from "@mui/icons-material/Battery0BarRounded";
import BrowseGalleryRounded from "@mui/icons-material/BrowseGalleryRounded";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DirectionsWalk from "@mui/icons-material/DirectionsWalk";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import FitnessCenterRounded from "@mui/icons-material/FitnessCenterRounded";
import HealthAndSafetyRounded from "@mui/icons-material/HealthAndSafetyRounded";
import ModeNightIcon from "@mui/icons-material/ModeNight";
import NoAccountsRounded from "@mui/icons-material/NoAccountsRounded";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import QuestionMark from "@mui/icons-material/QuestionMark";
import RemoveCircleRounded from "@mui/icons-material/RemoveCircleRounded";
import ScaleIcon from "@mui/icons-material/Scale";
import SportsMartialArtsIcon from "@mui/icons-material/SportsMartialArts";
import TagFacesIcon from "@mui/icons-material/TagFaces";
import TerrainIcon from "@mui/icons-material/Terrain";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import WbTwilightIcon from "@mui/icons-material/WbTwilight";
import type {
  TagGoal,
  TagObWindowsPreferred,
  TagSpecialty as TagSpecialtyType,
} from "@trainwell/types";

export type TagChallenge =
  | "getting_started"
  | "not_sure_what_to_do"
  | "not_enough_time"
  | "exercise_aversion"
  | "consistency"
  | "injury_or_illness"
  | "bored"
  | "no_energy"
  | "no_gym_access"
  | "none";

export type TagChallenge_PositiveValueCopy =
  | "improve_health"
  | "build_strength"
  | "lose_fat"
  | "be_consistent"
  | "look_more_fit"
  | "increase_confidence"
  | "break_through_plateaus"
  | "enjoy_exercise"
  | "overcome_injury"
  | "age_gracefully";

export const challengeData: {
  label: string;
  key: TagChallenge;
  icon: JSX.Element;
}[] = [
  {
    label: "Getting started",
    key: "getting_started",
    icon: <DirectionsWalk />,
  },
  {
    label: "Not sure what to do",
    key: "not_sure_what_to_do",
    icon: <QuestionMark />,
  },
  {
    label: "Finding time",
    key: "not_enough_time",
    icon: <BrowseGalleryRounded />,
  },
  {
    label: "Don’t like exercising",
    key: "exercise_aversion",
    icon: <AccessibilityNewRounded />,
  },
  {
    label: "Consistency",
    key: "consistency",
    icon: <FitnessCenterRounded />,
  },
  {
    label: "Injury/health issue",
    key: "injury_or_illness",
    icon: <HealthAndSafetyRounded />,
  },
  {
    label: "Boredom",
    key: "bored",
    icon: <AddReactionRounded />,
  },
  {
    label: "Not enough energy",
    key: "no_energy",
    icon: <Battery0BarRounded />,
  },
  {
    label: "No gym or equipment",
    key: "no_gym_access",
    icon: <NoAccountsRounded />,
  },
  {
    label: "Nothing specific",
    key: "none",
    icon: <RemoveCircleRounded />,
  },
];

export const goalCopy: {
  label: string;
  key: TagGoal;
  specialtyKey?: TagSpecialtyType;
  icon: JSX.Element;
}[] = [
  {
    label: "Build strength",
    specialtyKey: "strength",
    key: "build_strength",
    icon: <FitnessCenterIcon />,
  },
  {
    label: "Lose weight",
    specialtyKey: "weight_loss",
    key: "lose_weight",
    icon: <ScaleIcon />,
  },
  {
    label: "Improve health",
    key: "improve_health",
    specialtyKey: "general_health",
    icon: <FavoriteIcon />,
  },
  {
    label: "Be consistent",
    key: "be_consistent",
    icon: <CalendarMonthIcon />,
  },
  {
    label: "Look more fit",
    key: "look_more_fit",
    icon: <TagFacesIcon />,
  },
  {
    label: "Increase confidence",
    key: "increase_confidence",
    icon: <AutoAwesomeIcon />,
  },
  {
    label: "Overcome plateaus",
    key: "break_through_plateaus",
    icon: <TerrainIcon />,
  },
  {
    label: "Enjoy exercise",
    key: "enjoy_exercise",
    icon: <SportsMartialArtsIcon />,
  },
  {
    label: "Age gracefully",
    key: "age_gracefully",
    icon: <PermContactCalendarIcon />,
  },
];

export const obWindowsCopy: {
  label: string;
  key: TagObWindowsPreferred;
  icon: JSX.Element;
}[] = [
  {
    label: "Morning",
    key: "morning",
    icon: <WbTwilightIcon />,
  },
  {
    label: "Afternoon",
    key: "afternoon",
    icon: <WbSunnyIcon />,
  },
  {
    label: "Evening",
    key: "evening",
    icon: <ModeNightIcon />,
  },
  {
    label: "No preference",
    key: "none",
    icon: <RemoveCircleRounded />,
  },
];
