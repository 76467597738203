import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { Box, Button, Card, Container, Typography } from "@mui/material";
import { Footer, LoadingPage } from "@trainwell/ui";
import { useEffect } from "react";
import QRCode from "react-qr-code";
import { useSearchParams } from "react-router";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { fetchSharedWorkouts } from "src/slices/sharedWorkoutsSlice";

export default function OpenOnPhonePage() {
  const dispatch = useAppDispatch();
  const sharedWorkoutsStatus = useAppSelector(
    (state) => state.sharedWorkouts.status,
  );
  const sharedWorkouts = useAppSelector(
    (state) => state.sharedWorkouts.sharedWorkouts,
  );
  const sharedWorkoutsUrl = useAppSelector(
    (state) => state.sharedWorkouts.sharedWorkoutsUrl,
  );
  const [urlSearchParams] = useSearchParams();
  const querySharedWorkoutId = urlSearchParams.get("shared_workouts_id");

  useEffect(() => {
    if (sharedWorkoutsStatus === "idle" && querySharedWorkoutId) {
      dispatch(fetchSharedWorkouts(querySharedWorkoutId));
    }
  }, [sharedWorkoutsStatus, querySharedWorkoutId]);

  if (!sharedWorkouts || !sharedWorkoutsUrl) {
    return <LoadingPage />;
  }

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: 15,
          left: 20,
          marginLeft: 0,
        }}
      >
        <a href={"https://www.trainwell.net/" + location.search}>
          <img
            src="/assets/trainwell-wordmark.svg"
            alt="logo"
            width={125}
            height={37}
          />
        </a>
      </Box>
      <Container sx={{ my: 12, maxWidth: "680px" }}>
        <Card
          variant="outlined"
          sx={{
            p: { xs: 2, md: 8 },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              maxWidth: "380px",
              textAlign: "center",
              mt: 3,
              mb: 9,
              fontWeight: "bold",
            }}
          >
            Whoops! Looks like you&apos;re not on your phone
          </Typography>
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "230px", width: "100%" }}
            value={sharedWorkoutsUrl}
            viewBox={`0 0 256 256`}
          />
          <Typography
            sx={{
              maxWidth: "300px",
              textAlign: "center",
              mb: 6,
              mt: 7,
              fontWeight: "bold",
            }}
          >
            Scan this code on your phone with the trainwell app installed
          </Typography>
          <Button
            fullWidth
            variant="text"
            startIcon={<ChevronLeftRoundedIcon />}
            sx={{ mb: 3, maxWidth: "487px" }}
            href={`/claim-shared-workouts/${querySharedWorkoutId}`}
          >
            Go Back
          </Button>
        </Card>
      </Container>
      <Footer />
    </>
  );
}
