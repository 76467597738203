import { Box, Button, Card, Container, Typography } from "@mui/material";
import { Footer, LoadingPage } from "@trainwell/ui";
import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { fetchSharedWorkouts } from "src/slices/sharedWorkoutsSlice";
import OpenOnPhonePage from "./OpenOnPhonePage";

export default function SharedWorkoutPage() {
  const dispatch = useAppDispatch();
  const sharedWorkoutsStatus = useAppSelector(
    (state) => state.sharedWorkouts.status,
  );
  const sharedWorkouts = useAppSelector(
    (state) => state.sharedWorkouts.sharedWorkouts,
  );
  const sharedWorkoutsUrl = useAppSelector(
    (state) => state.sharedWorkouts.sharedWorkoutsUrl,
  );
  const { sharedWorkoutId } = useParams<{ sharedWorkoutId?: string }>();
  const [urlSearchParams] = useSearchParams();

  useEffect(() => {
    if (sharedWorkoutsStatus === "idle" && sharedWorkoutId) {
      dispatch(fetchSharedWorkouts(sharedWorkoutId));
    }
  }, [sharedWorkoutsStatus, sharedWorkoutId]);

  if (!sharedWorkouts) {
    return <LoadingPage />;
  }

  const { sharing_user } = sharedWorkouts;

  const querySharedWorkoutId = urlSearchParams.get("shared_workouts_id");

  if (querySharedWorkoutId) {
    // Routed back to web from the dynamic link

    return <OpenOnPhonePage />;
  }

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: 15,
          left: 20,
          marginLeft: 0,
        }}
      >
        <a href={"https://www.trainwell.net/" + location.search}>
          <img
            src="/assets/copilot-logo-dark.png"
            alt="logo"
            width={125}
            height={37}
          />
        </a>
      </Box>
      <Container sx={{ my: 12, maxWidth: "680px" }}>
        <Card
          variant="outlined"
          sx={{
            p: { xs: 2, md: 8 },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={sharing_user.headshot_url}
            width={112}
            height={112}
            style={{ borderRadius: "56px" }}
            alt={sharing_user.first_name}
          />
          <Typography
            variant="h2"
            sx={{ maxWidth: "300px", textAlign: "center", my: 3 }}
          >
            <b>{sharing_user?.first_name}</b> shared{" "}
            <b>
              {sharedWorkouts.workouts.length} workout
              {sharedWorkouts.workouts.length > 1 && "s"}
            </b>{" "}
            with you.
          </Typography>
          <Typography sx={{ maxWidth: "300px", textAlign: "center", mb: 7 }}>
            Create a free trainwell account or login to get started.
          </Typography>
          <Button
            fullWidth
            variant="contained"
            sx={{ mb: 3, maxWidth: "487px" }}
            href={`/?shared_workouts_id=${sharedWorkoutId}`}
          >
            Create Free Account
          </Button>
          <Button
            fullWidth
            variant="contained"
            sx={{ maxWidth: "487px" }}
            href={sharedWorkoutsUrl}
          >
            Login
          </Button>
        </Card>
      </Container>
      <Footer />
    </>
  );
}
