import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import {
  Box,
  Card,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { LoadingPage } from "@trainwell/ui";
import { useEffect } from "react";
import QRCode from "react-qr-code";
import { useSearchParams } from "react-router";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { api } from "src/lib/trainwellApi";
import { fetchSharedWorkouts } from "src/slices/sharedWorkoutsSlice";
import { selectChosenOS } from "src/slices/surveySlice";

export default function DownloadFreemiumPage() {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  const chosenOS = useAppSelector(selectChosenOS);
  const sharedWorkoutsStatus = useAppSelector(
    (state) => state.sharedWorkouts.status,
  );
  const userId = useAppSelector((state) => state.client.clientID);
  const sharedWorkouts = useAppSelector(
    (state) => state.sharedWorkouts.sharedWorkouts,
  );
  const sharedWorkoutsUrl = useAppSelector(
    (state) => state.sharedWorkouts.sharedWorkoutsUrl,
  );
  const [urlSearchParams] = useSearchParams();
  const querySharedWorkoutId = urlSearchParams.get("shared_workouts_id");

  useEffect(() => {
    if (sharedWorkoutsStatus === "idle" && querySharedWorkoutId) {
      dispatch(fetchSharedWorkouts(querySharedWorkoutId));
    }
  }, [sharedWorkoutsStatus, querySharedWorkoutId]);

  useEffect(() => {
    if (userId && sharedWorkouts?.id) {
      api.sharedWorkouts.link(sharedWorkouts.id, userId);
    }
  }, [sharedWorkouts?.id, userId]);

  if (!sharedWorkouts || !sharedWorkoutsUrl) {
    return <LoadingPage />;
  }

  if (isPhone) {
    return (
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: 4,
            p: 1,
            width: "100%",
            backgroundColor: "#F0FAF4",
          }}
        >
          <CheckCircleRoundedIcon
            color="success"
            fontSize="large"
            sx={{ mr: 1 }}
          />
          <Typography>Done! Your account is created.</Typography>
        </Box>
        <Typography sx={{ textAlign: "center", my: 3 }}>
          Download the trainwell app to get started
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          {chosenOS === "ios" && (
            <a
              href={
                "https://apps.apple.com/us/app/deltatrainer-fitness-training/id1466445684"
              }
            >
              {!isPhone && (
                <Box sx={{ mr: 4, display: "inline" }}>
                  <img
                    src="/assets/qr-codes/download-ios.svg"
                    alt="QR code to app store"
                    height={90}
                    width={90}
                  />
                </Box>
              )}
              <img
                src="/assets/download-on-the-app-store.svg"
                alt="Link to app store"
                height={90}
                width={170}
              />
            </a>
          )}
          {chosenOS === "android" && (
            <a
              // style={{ marginLeft: 25 }}
              href={
                "https://play.google.com/store/apps/details?id=com.mycopilot.copilotapp"
              }
            >
              {!isPhone && (
                <Box sx={{ mr: 4, display: "inline" }}>
                  <img
                    src="/assets/qr-codes/download-android.svg"
                    alt="QR code to play store"
                    height={90}
                    width={90}
                  />
                </Box>
              )}
              <img
                src="/assets/google-play-store.svg"
                alt="Link to play store"
                height={90}
                width={185}
              />
            </a>
          )}
        </Box>
      </Box>
    );
  } else {
    return (
      <Container sx={{ my: 4, maxWidth: "680px" }}>
        <Card
          variant="outlined"
          sx={{
            p: { xs: 2, md: 8 },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mt: 7,
              mb: 6,
              maxWidth: "380px",
            }}
          >
            <CheckCircleRoundedIcon
              color="success"
              fontSize="large"
              sx={{ mr: 1 }}
            />
            <Typography
              variant="h2"
              sx={{
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              You&apos;re in!
            </Typography>
          </Box>
          <Typography
            sx={{
              maxWidth: "400px",
              textAlign: "center",
              mb: 6,
            }}
          >
            Scan with your phone to download the trainwell app and get access to
            your shared workouts.
          </Typography>
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "230px", width: "100%" }}
            value={sharedWorkoutsUrl}
            viewBox={`0 0 256 256`}
          />
        </Card>
      </Container>
    );
  }
}
